import React from "react"
import 'twin.macro'

import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const CallToAction = ({...props}) =>
  <Link
    {...props}
    tw="inline-block mx-1 text-white no-underline rounded-3xl px-4 py-2 text-xs md:(text-sm) border-solid border-2 border-white border-opacity-30 hover:(border-opacity-50) transition">
      {props.children}
  </Link>

const Summary = () => {
  const data = useStaticQuery(graphql`
    {
      file(relativeDirectory: {eq: "mechanics-institute"}, extension: {eq: "jpg"}, childImageSharp: {fluid: {}}) {
        name
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <div tw="relative z-0 flex justify-center py-14">
      <div tw="relative z-20 max-w-lg px-5 text-center">
        <h2 tw="mb-5 text-white font-serif text-2xl md:(text-3xl) lg:(text-4xl) font-bold">
          A living guide to<br/>the city we live in.
        </h2>
        <p tw="m-0 text-white opacity-90 font-serif md:(text-lg) lg:(text-xl)">
          {data.site.siteMetadata.title} is a curated guide to Perth, free of sponsored posts and advertising.
        </p>
        <div tw="mt-5">
          <CallToAction to="/">Home</CallToAction>
          <CallToAction to="/places">Directory</CallToAction>
        </div>
      </div>
      <div tw="absolute z-10 inset-0 backgroundColor[#3C3E41] opacity-80 backdropFilter[blur(3px)]"/>
      <Img fluid={data.file.childImageSharp.fluid} tw="absolute! z-0 inset-0"/>
    </div>
  )
}

export default Summary