import React from "react"
import 'twin.macro'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import Summary from "../components/summary"
import Footer from "../components/footer"

const FourOhFour = () => {
  return (
    <Layout styleContext="feature">
      <Seo title="404 — Not Found"/>
      <Section>
        <div tw="text-center max-w-3xl mx-auto p-12">
          <h1 tw="text-7xl font-bold tracking-tighter">404’d</h1>
          <p tw="text-xl text-thirdgray-500 dark:(text-thirdgray-dark-500)">We could’t find what you were looking for.</p>
        </div>
      </Section>
      <Summary/>
      <Footer/>
    </Layout>
  )
}

export default FourOhFour
